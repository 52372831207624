<template>
  <v-container
    id="about"
    tag="section"
  >
    <base-subheading>About Me - 🍞</base-subheading>

    <p>
      I am a full time full stack C# software enginner and have been for over 13 years now. Dabble about in Java because, Minecraft. Total nerd and love programming.
    </p>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeAbout',
  }
</script>
